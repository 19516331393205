.Editor--saved-charts--container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 20px;
  grid-gap: 2em;
}

.Editor--saved-charts--container--card {
  margin: 0 !important;
  height: 100%;
}

.Editor--saved-charts--container--card--image > img {
  height: 100px !important;
  object-fit: cover;
}

.Editor--saved-charts--emptyContainer {
  display: flex;
  background: #f5f5f5;
  align-items: center;
  justify-content: center;
  min-height: 20em;
}

.Editor--actions--container {
  display: flex;
}