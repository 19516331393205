.RegionSelector--group--item--only {
  margin-left: 5px;
  cursor: pointer;
  text-decoration: underline;
  color: #4183c4;
  opacity: 0;
  transition: opacity ease 0.3s;
}

.RegionSelector--group--item:hover .RegionSelector--group--item--only {
  opacity: 0.5;
}

.RegionSelector--group--item:hover .RegionSelector--group--item--only:hover {
  opacity: 1;
  color: #1e70bf;
}
